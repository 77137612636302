/* eslint-disable no-underscore-dangle */
import _, { capitalize } from 'lodash';
import moment from 'moment';
import { Avatar, Chip, DotIndicator, Row, Text } from 'src/components';
import { INVOICE_STATUS, PATIENT_STATUS } from 'src/constants';
import { ServicesActionColumn } from 'src/pages/admin/ManageServices/ServicesActionColumn';
import ActionColumn from 'src/pages/admin/ManageStaff/ActionColumn';
import { FacilityActionColumn } from 'src/pages/admin/ManageFacility/ActionColumn';
import StaffNameRow from 'src/pages/admin/ManageStaff/StaffNameRow';
import { getColor } from 'src/pages/Calendar';
import {
  TableColumn,
  Staff,
  User,
  VisitTime,
  // ProductBatchType,
  Invoice,
} from 'src/types';
import { currencyformatter, generateAvatar } from 'src/utils';
import { HmoActionColumn } from 'src/pages/admin/ManageHMO/ActionColumn';
import { SettlementActionColumn } from 'src/pages/admin/HMODetails/Settlement/settlementActionColumn';
import { StepsActionColumn } from 'src/pages/admin/ManageSteps/stepsActionColumn';
import { InventoryActionColumn } from 'src/pages/admin/ManageInventory/InventoryActionColumn';
import { useStaff } from 'src/state';
import { TruncatedText } from 'src/pages/admin/ManageInventory/styled';
import { decodeHtmlEntities } from 'src/utils/decodeHtmlEntities';
import useMediaQuery from 'src/utils/useMediaQuery';

export const staffCoumns: Array<TableColumn> = [
  {
    title: 'Full Name',
    dataIndex: 'user.fullName',
    render: (fullName, row) => (
      <StaffNameRow
        fullName={fullName as string}
        avatar={(row as unknown as Staff).user.profileAvatar as string}
      />
    ),
  },
  { title: 'Email', dataIndex: 'user.emails[0]' },
  {
    title: 'Staff Group',
    dataIndex: 'staffGroup.name',
    render: (value) => {
      const stringValue = String(value);

      const result =
        stringValue.endsWith('s') || stringValue.toLowerCase() === 'owner'
          ? stringValue
          : `${stringValue}s`;
      return capitalize(result);
    },
  },
  {
    title: 'Permission',
    dataIndex: 'permissions',
    render: (permissions) =>
      Array.isArray(permissions) && permissions.length > 0
        ? `${_.startCase(permissions[0].name)}
      ${permissions.length > 1 ? '&' : ''} 
      ${permissions.length > 1 ? permissions.length - 1 : ''} 
      ${permissions.length > 1 ? 'more' : ''}`
        : '-',
  },
  { title: 'Status', dataIndex: 'status' },
  {
    title: 'Facility',
    dataIndex: 'facility.state',
    render: (state) => `${state} Branch`,
  },
  {
    title: 'Last Seen',
    dataIndex: 'updatedAt',
    render: (updatedAt) =>
      updatedAt === ''
        ? 'No Data'
        : moment(updatedAt as string, 'YYYYMMDD').fromNow(),
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    render: (id, row) => (
      // eslint-disable-next-line no-underscore-dangle
      <ActionColumn
        row={row}
        id={id as string}
        _id={row._id as string}
        invitationId={row.invitationId as string}
      />
    ),
  },
];

export const patientColumns: Array<TableColumn> = [
  { title: 'Patient ID', dataIndex: 'patientId' },
  {
    title: 'Full Name',
    dataIndex: 'user',
    render: (user) =>
      `${_.capitalize((user as unknown as User).firstName)} ${_.capitalize(
        (user as unknown as User).lastName,
      )}`,
  },
  { title: 'Phone Number', dataIndex: 'basicInfo.phoneNumber' },
  {
    title: 'Date of Birth',
    dataIndex: 'basicInfo.dateOfBirth',
    render: (date) => !date ? 'UNKNOWN' :
      moment(date as string, 'YYYY-MM-DDTHH:mm:ssZ').format('Do MMM[,] YYYY'),
  },
  {
    title: 'Gender',
    dataIndex: 'basicInfo.gender',
    render: (gender) => capitalize(gender as string),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status) => (
      <Chip
        type="round"
        color={
          PATIENT_STATUS[
            (status as string).toUpperCase() as keyof typeof PATIENT_STATUS
          ]
        }
      >
        {status === 'CHECKED_OUT' ? 'INACTIVE' : _.startCase(status as string)}
      </Chip>
    ),
  },
  {
    title: 'Last seen',
    dataIndex: 'updatedAt',
    render: (updatedAt) => moment(updatedAt as string).fromNow(),
  },
];

export const servicesColumn: Array<TableColumn> = [
  { title: 'Service Code', dataIndex: 'serviceCode' },
  { title: 'Description', dataIndex: 'description' },
  {
    title: 'Category',
    dataIndex: 'category',
    render: (category) => (category as string) || '',
  },
  {
    title: 'Amount (₦)',
    dataIndex: 'amount',
    render: (amount) =>
      currencyformatter.format(parseInt(amount as string, 10)),
  },
  {
    title: 'HMO Payable',
    dataIndex: 'isHMOPayable',
    render: (isHMOPayable) => (isHMOPayable ? 'YES' : 'NO'),
  },
  {
    title: 'Facility',
    dataIndex: 'facility.state',
    render: (state) => `${state} Branch`,
  },
  { title: 'Status', dataIndex: 'status' },
  {
    title: 'Actions',
    dataIndex: 'id',
    render: (id, row) => (
      <ServicesActionColumn
        row={row}
        id={id as string}
        _id={row._id as string}
      />
    ),
  },
];

export const scheduleColumn: Array<TableColumn> = [
  {
    title: '',
    dataIndex: 'visitTime.startTime',
    render: (time) =>
      moment(time as unknown as string, 'hh:mm a').format('h:mm A'),
  },
  {
    title: '',
    dataIndex: 'type',
    render: (type) => (
      <DotIndicator color={getColor(type as string)} />
    ),
  },
  {
    title: '',
    dataIndex: 'type',
  },
  {
    title: '',
    dataIndex: 'visitTime',
    render: (visitTime) => {
      const getStartTime = moment(
        (visitTime as unknown as VisitTime)?.startTime,
        'hh:mm a',
      );
      const getEndTime = moment(
        (visitTime as unknown as VisitTime)?.endTime,
        'hh:mm a',
      );
      const diff = moment(getEndTime).diff(getStartTime);
      const minutes = moment.duration(diff).minutes();
      const hours = moment.duration(diff).hours();

      return `${hours > 0 ? hours : ''}
      ${hours > 1 ? 'hrs' : ''}
      ${hours === 1 ? 'hr' : ''} 
      ${minutes > 0 ? minutes : ''}
      ${minutes > 1 ? 'mins' : ''}
      ${minutes === 1 ? 'a min' : ''}`;
    },
  },
  {
    title: '',
    dataIndex: 'patient.user',
    render: (user) => {
      const { firstName, lastName, profileAvatar } = user as unknown as User;
      const fullName = firstName.concat(' ', lastName);
      return (
        <Row align="center" gap={0.375} width="max-content">
          <Avatar size={34} src={profileAvatar || generateAvatar(fullName)} />
          <Text>{fullName}</Text>
        </Row>
      );
    },
  },
];

export const activePatientsColumn: Array<TableColumn> = [
  {
    title: 'Full Name',
    dataIndex: 'user',
    render: (user) => {
      const { firstName, lastName, profileAvatar } = user as unknown as User;
      const fullName = firstName.concat(' ', lastName);

      return (
        <Row gap={0.5} align="center" width="max-content">
          <Avatar src={profileAvatar || generateAvatar(fullName)} size={34} />
          {_.startCase(fullName)}
        </Row>
      );
    },
  },
  { title: 'Phone Number', dataIndex: 'basicInfo.phoneNumber' },
  {
    title: 'Date of Birth',
    dataIndex: 'basicInfo.dateOfBirth',
    render: (date) => !date ? 'UNKNOWN' :
      moment(date as string, 'YYYY-MM-DDTHH:mm:ssZ').format('Do MMM[,] YYYY'),
  },
  {
    title: 'Gender',
    dataIndex: 'basicInfo.gender',
    render: (gender) => capitalize(gender as string),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status) => (
      <Chip
        type="round"
        color={
          PATIENT_STATUS[
            (status as string).toUpperCase() as keyof typeof PATIENT_STATUS
          ]
        }
      >
        {status === 'CHECKED_OUT' ? 'INACTIVE' : _.startCase(status as string)}
      </Chip>
    ),
  },
  {
    title: 'Last Seen',
    dataIndex: 'updatedAt',
    render: (updatedAt) => moment(updatedAt as string).format('h:mm[,] D MMM'),
  },
];

export const facilityColumn: Array<TableColumn> = [
  { title: 'Facility Address', dataIndex: 'address' },
  {
    title: 'Number of Staff',
    dataIndex: 'staffCount',
  },
  { title: 'State', dataIndex: 'state' },
  { title: 'Country', dataIndex: 'country' },
  {
    title: 'Date Added',
    dataIndex: 'createdAt',
    render: (createdAt) => moment(createdAt as string).format('MMM DD[,] YYYY'),
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    render: (id, row) => <FacilityActionColumn id={id as string} row={row} />,
  },
];

export const hmoColumns: Array<TableColumn> = [
  { title: 'HMO ID', dataIndex: 'hmoId' },
  {
    title: 'HMO Name',
    dataIndex: 'name',
  },
  {
    title: 'Number of Patients',
    dataIndex: 'numberOfPatients',
  },
  {
    title: 'Date Registered',
    dataIndex: 'createdAt',
    render: (createdAt) => moment(createdAt as string).format('MMM DD[,] YYYY'),
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    render: (id, row) => <HmoActionColumn id={id as string} row={row} />,
  },
];

export const invoiceColumns: Array<TableColumn> = [
  { title: 'Invoice ID', dataIndex: 'invoiceId' },
  {
    title: 'Invoice Amount',
    dataIndex: 'amount',
    render: (amount) =>
      currencyformatter.format(parseInt(amount as string, 10)),
  },
  {
    title: 'Invoice Status',
    dataIndex: 'status',
    render: (status) => (
      <Chip
        type="round"
        modStyles={{ px: 0.6, py: 0.2 }}
        color={
          INVOICE_STATUS[
            (status as string).toUpperCase() as keyof typeof INVOICE_STATUS
          ]
        }
      >
        {status === 'PAID' ? 'Paid' : 'Unpaid'}
      </Chip>
    ),
  },
  {
    title: 'Date',
    dataIndex: 'markedAt',
    render: (markedAt) => moment(markedAt as string).format('D, MMM, YYYY'),
  },
];

export const inventoryInvoiceColumn: Array<TableColumn> = [
  { title: 'Invoice ID', dataIndex: 'invoiceId' },
  {
    title: 'Invoice Type',
    dataIndex: 'invoiceType',
  },
  {
    title: 'Invoice Amount',
    dataIndex: 'amount',
    render: (hmoAmount, row) => {
      const inv = row as unknown as Invoice;
      const amount = (inv.oopAmount || 0) + (inv.hmoAmount || 0);
      const unpaidAmount = inv.items.reduce((acc, item) => {
        acc += (item.unitCost || 0) * (item.units || 0);
        return acc;
      }, 0);
      return currencyformatter.format(
        parseInt(
          Number(inv.status === 'PAID' ? amount : unpaidAmount).toString(),
          10,
        ),
      );
    },
  },
  {
    title: 'Invoice Status',
    dataIndex: 'status',
    render: (status) => (
      <Chip
        type="round"
        modStyles={{ px: 0.6, py: 0.2 }}
        color={
          INVOICE_STATUS[
            (status as string).toUpperCase() as keyof typeof INVOICE_STATUS
          ]
        }
      >
        {status === 'PAID' ? 'Paid' : 'Unpaid'}
      </Chip>
    ),
  },
  {
    title: 'Date',
    dataIndex: 'markedAt',
    render: (markedAt, row) => {
      const inv = row as unknown as Invoice;
      return moment(
        (inv.status === 'PAID' ? inv.markedAt : inv.updatedAt) as string,
      ).format('D, MMM, YYYY');
    },
  },
];

export const HMOPatientsColumn: Array<TableColumn> = [
  { title: 'Patient ID', dataIndex: 'patientId' },
  {
    title: 'Full Name',
    dataIndex: 'user',
    render: (user) => {
      const { firstName, lastName } = user as unknown as User;
      const fullName = firstName.concat(' ', lastName);
      return _.startCase(fullName);
    },
  },
  {
    title: 'Phone Number',
    dataIndex: 'basicInfo.phoneNumber',
  },
  {
    title: 'Date Added',
    dataIndex: 'createdAt',
    render: (createdAt) => moment(createdAt as string).format('MMM. D, YYYY'),
  },
];

export const HMOSettlementColumn: Array<TableColumn> = [
  { title: 'Settlement ID', dataIndex: 'settlementId' },
  {
    title: 'Total Debt',
    dataIndex: 'debt',
    render: (debt) => currencyformatter.format(parseInt(debt as string, 10)),
  },
  {
    title: 'Settlement Amount',
    dataIndex: 'amount',
    render: (amount) =>
      currencyformatter.format(parseInt(amount as string, 10)),
  },
  {
    title: 'Settlement Date',
    dataIndex: 'createdAt',
    render: (createdAt) => moment(createdAt as string).format('D MMM., YYYY'),
  },
  {
    title: '',
    dataIndex: 'proof',
    render: (proof) => <SettlementActionColumn proof={proof as string} />,
  },
];

export const StepsColumn: Array<TableColumn> = [
  { title: 'Step ID', dataIndex: 'stepTemplateId' },
  { title: 'Step Name', dataIndex: 'name' },
  { title: 'Number of Actions', dataIndex: 'actions.length' },
  {
    title: 'Date Created',
    dataIndex: 'createdAt',
    render: (createdAt) => moment(createdAt as string).format('MMM. D, YYYY'),
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    render: (id, row) => <StepsActionColumn id={id as string} row={row} />,
  },
];

export const InventoryColumn: () => Array<TableColumn> = () => {
  const { hasPermission } = useStaff();
  const isMobile = !useMediaQuery('(min-width: 960px)');

  const columns: Array<TableColumn> = [
    { title: 'Product Code', dataIndex: 'productCode' },
    {
      title: 'Product Name',
      dataIndex: 'name',
      render: (name) => <TruncatedText>{decodeHtmlEntities(name as string)}</TruncatedText>,
    },
    {
      title: 'Brand Name',
      dataIndex: 'brandName',
      render: (brandName) => (
        <TruncatedText>{brandName as string}</TruncatedText>
      ),
    },
    {
      title: 'Measurement/Form',
      dataIndex: 'form',
    },
    {
      title: 'OTC',
      dataIndex: 'isOTC',
      render: (isOTC) => (isOTC ? 'Yes' : 'No'),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (id, row) => <InventoryActionColumn row={row} />,
    },
  ]
  
  return columns.filter(column => {
    if (!hasPermission('ARCHIVE_PRODUCT')) {
      return column.title.toLowerCase() !== 'actions';
    }

    if (isMobile) {
      return !['product code', 'brand name'].includes(column.title.toLowerCase());
    }

    return true;
  });
};
