/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TimelineImage } from 'src/assets/images';
import { Column } from 'src/components';
import { openModal } from 'src/utils';
import { usePatientDetails } from 'src/pages/admin/PatientDetails/hooks/usePatientDetails';
import { useAlert } from 'src/state';
import { EmptyText } from './styled';

export const NoChart: React.FC = () => {
  const { patient } = usePatientDetails();
  const { showAlert, removeAlert } = useAlert();
  const openAlert = () => {
    showAlert({
      title: 'Action Required',
      type: 'decision',
      message: `The patient's age is required to ensure accurate medical records and treatment plans. Please verify the patient's date of birth.`,
      closeText: 'Close',
      confirmText: 'Edit Patient',
      confirm: () => {
        removeAlert();
        openModal('edit-patient', patient?.id);
      },
    });
  };

  return (
    <Column align="center" gap={1} justify="center" height="100%" width="100%">
      <TimelineImage />
      <EmptyText align="center">
        Nothing to display.{' '}
        <span
          role="button"
          tabIndex={0}
          onClick={() => {
            if (!patient?.basicInfo?.dateOfBirth) {
              openAlert();
              return;
            }
            openModal('form', process.env.REACT_APP_VITALS_FORM_ID);
          }}
        >
          Add vitals
        </span>{' '}
        to view chart
      </EmptyText>
    </Column>
  );
};
