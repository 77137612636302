export const getColor = (status: string) => {
  switch (status) {
    case 'Pending':
      return 'secondary';
    case 'Done':
      return 'green';
    case 'Ongoing':
      return 'yellow';
    default:
      return 'grey';
  }
};

export const getTagActionLink = (patientId: string) =>
  ({
    'Manual Action': '',
    'Take Vitals': `/admin/patients/${patientId}?tab=summary#form&id=${process.env.REACT_APP_VITALS_FORM_ID}`,
    'Create Invoice': `/admin/patients/${patientId}?tab=invoice`,
    'Log Medication': `/admin/patients/${patientId}?tab=summary#medications`,
    'Add Record': `/admin/patients/${patientId}?tab=timeline&view=forms`,
    'Take Note': `/admin/patients/${patientId}?tab=timeline`,
    'Add Allergy': `/admin/patients/${patientId}?tab=summary#allergies`,
  } as Record<string, string>);
